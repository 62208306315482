.carousel-container {
    background-color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    padding-bottom: 80px;
  }

  .carousel-container img {
    height: 700px;
    border-radius: 8px;
  }

  .carousel-container h1 {
    color: white;
    text-align: center;
    font-size: 46px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-width: 480px) { 
    .carousel-container img {
      height: 300px;
    }

    .carousel-container h1 {
      font-size: 36px;
    }
  }
