/* estilos GLOBAIS  */

body {
  margin: 0;
  padding: 0;
}
h1, h2 {
  font-family: 'Arvo', serif;
}

p {
  font-family: 'Open Sans', sans-serif;
}