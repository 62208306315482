.buttonStyle {
    margin-right: 3vw; /* Defina o valor de margem desejado */
    text-align: right;
    background: none;
    border: none;
    background-color: #377837;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 24px;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
  }

  
  @media (max-width: 480px) {
    .buttonStyle {
      margin-bottom: 5px;
    }
  }

  @media (min-width: 768px) and (max-width: 834px) {
    .buttonStyle {
      padding: 8px 16px;
      font-size: 20px;
    }
  }