
.textContainer {
    position: relative;
    color: #ffffff;
    background-image: url('../assets/painel-verde-grande-escuro.jpg');
    width: 100%;
    height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.textContainer h1 {
    margin: 0;
    position: absolute;
    top: 30%;
    left: 10%;
    text-align: left;
    font-size: 42px;
}

.textContainer p {
    margin: 0;
    position: absolute;
    font-size: 26px;
    top: 55%;
    left: 10%;
    margin: 0;
    text-align: left;
}


@media (max-width: 480px) {
    .textContainer {
        position: relative;
        height: 520px;
        background-size: cover;
        background-position: center center;
    }

    .textContainer h1 {
        position: absolute;
        font-size: 24px;
    }

    .textContainer p {
        position: absolute;
        top: 50%;
        font-size: 18px;
        line-height: 1.5;
        text-align: left;
    }
}

@media (min-width: 768px) and (max-width: 834px) {

    .textContainer {
        position: relative;
        height: 820px;
    }

    .textContainer h1 {
        top: 35%;
    }

    .textContainer p {
        position: absolute;
        top: 55%;
        font-size: 20px;
        line-height: 1.5;
        text-align: left;
    }
}