.full-width-container {
    background: #48773F;
    width: 100vw;
    
  }

.container {
    padding-top: 60px;
    padding-bottom: 60px;
}

.container h1 {
    text-align: center;
    color: white;
    font-size: 46px;
    padding-bottom: 20px;
}

.container.text-center{
    background: #48773F;
}
