.gridContainer {
    background: #48773F;
    align-items: center;
    justify-content: center;
}

.gridContainer img {
    height: 260px;
    width: 350px;
    border-radius: 3px;
}

.gridContainer h2, p {
    color: white;
    padding-top: 10px;
}

@media (min-width: 768px) and (max-width: 834px) {
    .gridContainer img {
        height: 200px;
        width: 300px;
    }
}

.image-grid {
  transition: transform 0.3s ease; /* Adicione uma transição suave de 0,3 segundos para a propriedade 'transform' */
}

.image-grid:hover {
  transform: scale(1.1); /* Aumenta a escala da imagem em 10% quando o mouse passa por cima */
}