/* tablet 834
cell 480 */

.headerContainer {
    background-color: #111111;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .centeredTitle {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin: 0;
    padding: 30px;
    flex: 1; /* Para ocupar o espaço disponível */
    margin-left: 250px; /* fica no meio da tela */
  }

  .centeredTitle a {
    color: #fff;
  }
  
  /* responsividade para celular */
  @media (max-width: 480px) {
    .headerContainer {
        background-color: #111111;
        display: flex;
        justify-content: center; /* Alteração para centralizar */
        align-items: center;
        flex-direction: column; /* Alteração para empilhar verticalmente */
      }
    
      .centeredTitle {
        color: #fff;
        text-align: center;
        font-size: 20px;
        margin: 0;
        padding: 30px;
        margin-left: 0; /* Removendo o margin-left */
      }
  }
  
/* responsividade para tablet */
  @media (min-width: 768px) and (max-width: 834px) {
    .headerContainer {
        background-color: #111111;
        display: flex;
        justify-content: center; /* Alteração para centralizar */
        align-items: center;
        
      }
    
      .centeredTitle {
        color: #fff;
        text-align: center;
        font-size: 20px;
        margin: 0;
        padding: 30px;
        margin-left: 250px; /* Removendo o margin-left */
      }
  }
